.liquide {
    height: 111px;
    width: 178px;
  }
  
  .liquide .couleur-du-liquide {
    background-color: #ffffff;
    border: 8px solid;
    border-color: #ffc700;
    height: 111px;
    margin-top: 10px;
  }

  .liquide .couleur-du-liquideMobile {
    background-color: #fff;
    border: 8px solid #ffc700;
    height: 127px;
    width: 200px;
    position: absolute;
    margin-top: -25px;
    margin-left: -82px;
    display: flex;
    justify-content: center;
  }
  
  .liquide .overlap-group {
    height: 84px;
    left: 4px;
    position: relative;
    top: 14px;
  }
  
  .liquide .group {
    height: 30px;
    left: 0;
    position: absolute;
    top: 54px;
    width: 74px;
  }
  
  .liquide .text-wrapper {
    color: #00226c;
    font-family: "RicardOmbre-Black", Helvetica;
    font-size: 13px;
    font-weight: 900;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
  }
  
  .liquide .jaune {
    left: 2px;
    position: absolute;
    top: 0;
  }
  
  .liquide .div {
    color: #00226c;
    font-family: "RicardSerre-Normal", Helvetica;
    font-size: 13px;
    font-weight: 400;
    left: 8px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 30px;
    transform: rotate(45deg);
    white-space: nowrap;
  }
  
  .liquide .vector {
    height: 13px;
    left: 6px;
    position: absolute;
    top: 12px;
    width: 6px;
  }
  
  .liquide .ellipse {
    background-color: #00226c;
    border: 1px solid;
    border-color: #00226c;
    border-radius: 6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
  }
  
  .liquide .overlap-group-2 {
    height: 75px;
    position: relative;
    width: 156px;
  }
  
  .liquide .jaune-2 {
    height: 53px;
    left: 0;
    position: absolute;
    top: 0;
    width: 40px;
  }
  
  .liquide .ellipse-2 {
    border: 1px solid;
    border-color: #00226c;
    border-radius: 6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
  }
  
  .liquide .bleu {
    height: 47px;
    position: absolute;
    top: 0;
  }
  
  .liquide .text-wrapper-2 {
    color: #00226c;
    font-family: "RicardSerre-Normal", Helvetica;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 27px;
    transform: rotate(45deg);
    white-space: nowrap;
  }
  
  .liquide .img {
    height: 13px;
    position: absolute;
    top: 12px;
    width: 6px;
  }
  
  .liquide .ellipse-3 {
    border: 1px solid;
    border-color: #00226c;
    border-radius: 6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
  }
  
  .liquide .bleu-frais {
    height: 65px;
    position: absolute;
    top: 0;
  }
  
  .liquide .text-wrapper-3 {
    color: #00226c;
    font-family: "RicardSerre-Normal", Helvetica;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 36px;
    transform: rotate(45deg);
    white-space: nowrap;
  }
  
  .liquide .ellipse-4 {
    border: 1px solid;
    border-color: #00226c;
    border-radius: 6.5px/6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 13px;
  }
  
  .liquide .transparent {
    height: 75px;
    left: 72px;
    position: absolute;
    top: 0;
    width: 63px;
  }
  
  .liquide .text-wrapper-4 {
    color: #00226c;
    font-family: "RicardSerre-Normal", Helvetica;
    font-size: 13px;
    font-weight: 400;
    left: 3px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 41px;
    transform: rotate(45deg);
    white-space: nowrap;
  }
  
  .liquide .vector-2 {
    height: 13px;
    left: 6px;
    position: absolute;
    top: 12px;
    width: 5px;
  }
  
  .liquide .ellipse-5 {
    border: 1px solid;
    border-color: #00226c;
    border-radius: 6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
  }
  
  .liquide .rose {
    height: 50px;
    left: 96px;
    position: absolute;
    top: 0;
    width: 38px;
  }
  
  .liquide .text-wrapper-5 {
    color: #00226c;
    font-family: "RicardSerre-Normal", Helvetica;
    font-size: 13px;
    font-weight: 400;
    left: 9px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 28px;
    transform: rotate(45deg);
    white-space: nowrap;
  }
  
  .liquide .ellipse-6 {
    border: 1px solid;
    border-color: #00226c;
    border-radius: 6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
  }
  
  .liquide .vert {
    height: 47px;
    left: 121px;
    position: absolute;
    top: 0;
    width: 35px;
  }
  
  .liquide .text-wrapper-6 {
    color: #00226c;
    font-family: "RicardSerre-Normal", Helvetica;
    font-size: 13px;
    font-weight: 400;
    left: 9px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 27px;
    transform: rotate(45deg);
    white-space: nowrap;
  }
  
  .liquide .vector-3 {
    height: 12px;
    left: 6px;
    position: absolute;
    top: 12px;
    width: 6px;
  }
  
  .liquide .ellipse-7 {
    border: 1px solid;
    border-color: #00226c;
    border-radius: 6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
  }
  
  .liquide .bleu-2 {
    height: 47px;
    left: 25px;
    position: absolute;
    top: 0;
    width: 36px;
  }
  
  .liquide .text-wrapper-7 {
    color: #00226c;
    font-family: "RicardSerre-Normal", Helvetica;
    font-size: 13px;
    font-weight: 400;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 27px;
    transform: rotate(45deg);
    white-space: nowrap;
  }
  
  .liquide .ellipse-8 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #00226c;
    border-radius: 6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 12px;
  }
  
  .liquide .bleu-frais-2 {
    height: 65px;
    left: 49px;
    position: absolute;
    top: 0;
    width: 54px;
  }
  
  .liquide .text-wrapper-8 {
    color: #00226c;
    font-family: "RicardSerre-Normal", Helvetica;
    font-size: 13px;
    font-weight: 400;
    left: 6px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 36px;
    transform: rotate(45deg);
    white-space: nowrap;
  }
  
  .liquide .ellipse-9 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: #00226c;
    border-radius: 6.5px/6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 13px;
  }
  
  .liquide .transparent-2 {
    height: 75px;
    left: 74px;
    position: absolute;
    top: 0;
    width: 63px;
  }
  
  .liquide .rose-2 {
    height: 50px;
    left: 98px;
    position: absolute;
    top: 0;
    width: 38px;
  }
  
  .liquide .vert-2 {
    height: 47px;
    left: 123px;
    position: absolute;
    top: 0;
    width: 35px;
  }
  
  .liquide .toolbox-liquide-5 {
    width: 156px;
  }
  
  .liquide .toolbox-liquide-3 {
    width: 156px;
  }
  
  .liquide .toolbox-liquide-2 {
    width: 156px;
  }
  
  .liquide .toolbox-liquide-4 {
    width: 156px;
  }
  
  .liquide .toolbox-liquide-6 {
    width: 156px;
  }
  
  .liquide .toolbox-liquide-1 {
    width: 158px;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .jaune {
    height: 75px;
    width: 154px;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .jaune {
    height: 75px;
    width: 154px;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .jaune {
    height: 75px;
    width: 154px;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .jaune {
    height: 75px;
    width: 154px;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .jaune {
    height: 75px;
    width: 154px;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .jaune {
    height: 53px;
    width: 40px;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .bleu {
    left: 24px;
    width: 35px;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .bleu {
    left: 23px;
    width: 36px;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .bleu {
    left: 23px;
    width: 36px;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .bleu {
    left: 24px;
    width: 35px;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .bleu {
    left: 24px;
    width: 35px;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .bleu {
    left: 24px;
    width: 35px;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .text-wrapper-2 {
    left: 9px;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .text-wrapper-2 {
    left: 10px;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .text-wrapper-2 {
    left: 10px;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .text-wrapper-2 {
    left: 9px;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .text-wrapper-2 {
    left: 9px;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .text-wrapper-2 {
    left: 9px;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .img {
    left: 4px;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .img {
    left: 6px;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .img {
    left: 6px;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .img {
    left: 4px;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .img {
    left: 4px;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .img {
    left: 4px;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .ellipse-3 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .ellipse-3 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .ellipse-3 {
    background-color: #09f5f5;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .ellipse-3 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .ellipse-3 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .ellipse-3 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .bleu-frais {
    left: 47px;
    width: 54px;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .bleu-frais {
    left: 48px;
    width: 53px;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .bleu-frais {
    left: 47px;
    width: 54px;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .bleu-frais {
    left: 47px;
    width: 54px;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .bleu-frais {
    left: 47px;
    width: 54px;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .bleu-frais {
    left: 47px;
    width: 54px;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .text-wrapper-3 {
    left: 6px;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .text-wrapper-3 {
    left: 5px;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .text-wrapper-3 {
    left: 6px;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .text-wrapper-3 {
    left: 6px;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .text-wrapper-3 {
    left: 6px;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .text-wrapper-3 {
    left: 6px;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .ellipse-4 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .ellipse-4 {
    background-color: #FFC700;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .ellipse-4 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .ellipse-4 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .ellipse-4 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .ellipse-4 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .ellipse-5 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .ellipse-5 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .ellipse-5 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .ellipse-5 {
    background-color: #b6710bf1;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .ellipse-5 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .ellipse-5 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .ellipse-6 {
    background-color: #fc0808;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .ellipse-6 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .ellipse-6 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .ellipse-6 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-5 .ellipse-7 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-3 .ellipse-7 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-2 .ellipse-7 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-4 .ellipse-7 {
    background-color: #ffffff;
  }
  
  .liquide .overlap-group.toolbox-liquide-6 .ellipse-7 {
    background-color: #5fe530;
  }
  
  .liquide .overlap-group.toolbox-liquide-1 .ellipse-7 {
    background-color: #ffffff;
  }