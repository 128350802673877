@font-face {
  font-family: 'Ricard-Black';
  src: url('./assets/font/RICARD/RICARD/Ricard-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ricard-Light';
  src: url('./assets/font/RICARD/RICARD/Ricard-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ricard-Gras';
  src: url('./assets/font/RICARD/RICARD/Ricard-Gras.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RicardAllonge-Black';
  src: url('./assets/font/RICARD/RICARD/RicardAllonge-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RicardAllonge-Gras';
  src: url('./assets/font/RICARD/RICARD/RicardAllonge-Gras.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ricard';
  src: url('./assets/font/RICARD/RICARD/Ricard-Normal.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ricard-Normal';
  src: url('./assets/font/RICARD/RICARD/Ricard-Normal.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RicardSerre-Normal';
  src: url('./assets/font/RICARD/RICARD/RicardSerre-Normal.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RicardOmbre-Black';
  src: url('./assets/font/RICARDOMBRE/RicardOmbre-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RicardOmbreAllonge-Black';
  src: url('./assets/font/RICARDOMBRE/RicardOmbreAllonge-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RicardOmbreCompact-Black';
  src: url('./assets/font/RICARDOMBRE/RicardOmbreCompact-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RicardOmbreSerre-Black';
  src: url('./assets/font/RICARDOMBRE/RicardOmbreSerre-Black.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RicardScripte-Regular';
  src: url('./assets/font/RICARDSCRIPTE/RicardScripte-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RicardAllonge';
  src: url('./assets/font/RICARD/RICARD/RicardAllonge-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}


body {
  margin: 0;
  font-family: "Ricard", Helvetica;
}

.slider-containerZ {
  width: 100%;
  margin-left: calc(50% - 555px);
  overflow: auto;
  margin-bottom: 20px;
}

.slick-list {
  padding: 0 !important;
}

.slick-slide {
  width: 611px !important;
}

.swiper-slide:nth-child(2n) {
  width: 100%;
}

.swiper-horizontal .swiper-pagination-bullets .swiper-pagination-bullets-dynamic,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  width: 80px;
  bottom: -15px;
  position: relative;
  height: 25px;
  
}

.swiper-pagination-bullet{
  background-color: grey !important;
}

.swiper-slide:nth-child(3n) {
  width: 100%;
}

.swiper-slide,
.swiper-slide-active {
  margin-right: "250px";
}