@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f2f3f3;
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #b5b6b6;
  border-radius: 14px;
  border: 1px solid #f2f3f3;
}



/* .wrapper {
  position: relative;
} */
.blueBox {
  background-color: #00226C;
  padding-bottom: '5px';
  padding-top: '5px'
}

.menu {
  position: fixed;
}

.menu.sticky {
  position: fixed;
}

[data-section] {
  height: 100%;
}

/* .wrapper::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: '#00226C';
  mix-blend-mode: screen;
} */